<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <li class="simple-node">
      <span>{{node.key}}: {{node.value}}</span>
      <eon-ui-link hide-icon="true" size="small" (click)="applyValue(node.value)" text="Apply"></eon-ui-link>
    </li>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <li>
      <div class="mat-tree-node">
        <mat-icon matTreeNodeToggle>
          {{
            treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
          }}
        </mat-icon>
        {{node.key}}
      </div>
    </li>
  </mat-tree-node>
</mat-tree>
