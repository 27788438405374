<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">Choose unit</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div class="spin-container" *ngIf="loadingDataPoints">
    <mat-spinner class="initial-loader" diameter="48" strokeWidth="2" color="warn"></mat-spinner>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataPoints" matSort *ngIf="!loadingDataPoints">
      <ng-container matColumnDef="dataType">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="dataType">Data Type</th>

        <td mat-cell *matCellDef="let dataPoint">
          {{dataPoint.data_type}}
        </td>

      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="name">Name</th>

        <td mat-cell *matCellDef="let dataPoint">
          {{dataPoint.name}}
        </td>

      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="value">Value</th>

        <td mat-cell *matCellDef="let dataPoint">
          <span *ngIf="dataPoint.data_type  !== 'JSON'">{{dataPoint.value}}</span>
          <eis-json-viewer *ngIf="dataPoint.data_type  === 'JSON'" [jsonData]="dataPoint.value" (valueApplied)="unitApplied($event)"></eis-json-viewer>
        </td>

      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header="actions">Actions</th>

        <td mat-cell *matCellDef="let dataPoint">
          <eon-ui-link *ngIf="dataPoint.data_type  !== 'JSON'" hide-icon="true" size="small" (click)="applyUnit(dataPoint)" text="Apply"></eon-ui-link>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <eon-ui-button [text]="'general.close' | translate"
                 scheme="red"
                 (click)="closeDialog()"></eon-ui-button>
</div>

