import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface JsonNode {
  key: string;
  value: any;
  children?: JsonNode[];
}

interface FlatNode {
  expandable: boolean;
  key: string;
  level: number;
}

@Component({
  selector: 'eis-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent {
  private _jsonData: any;

  get jsonData(): string {
    return this._jsonData;
  }

  @Input()
  set jsonData(val: any) {
    if(typeof val == "string") {
      this._jsonData = JSON.parse(val);
    } else {
      this._jsonData = val;
    }
  }

  @Output()
  public valueApplied = new EventEmitter<string>();

  private _transformer = (node: JsonNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      key: node.key,
      value: node.value,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener<JsonNode, FlatNode, FlatNode>(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;

  constructor() {}

  ngOnChanges() {
    const data = this.buildJsonTree(this.jsonData);
    this.dataSource.data = data;
  }

  // Convert JSON object to JsonNode structure
  buildJsonTree(obj: any, key: string = ''): JsonNode[] {
    return Object.keys(obj).map(k => {
      const value = obj[k];
      if (value !== null && typeof value === 'object') {
        return {
          key: k,
          value: null,
          children: this.buildJsonTree(value, k)
        };
      } else {
        return {
          key: k,
          value: value
        };
      }
    });
  }

  applyValue(value: string) {
    this.valueApplied.emit(value);
  }
}
