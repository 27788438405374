import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MBusDataPoint } from '../../../../domain/device.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '../../../../services/device.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as dayjs from 'dayjs';
import { NgxDataToCsvService } from '../../../../services/ngx-data-to-csv.service';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { OPCUAEIoTDataPoint } from '../../../../domain/opcua-eiot-data-point';

interface ErrorDetail {
  category: String,
  elementHint: String,
  message: String,
  position: String
}

@Component({
  selector: 'eis-gateway-opcua-edit-csv-labels-dialog',
  templateUrl: './opcua-edit-csv-labels-dialog.component.html',
  styleUrls: ['./opcua-edit-csv-labels-dialog.component.scss']
})
export class OpcuaEditCsvLabelsDialogComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  file: File | null;
  step: number = 1;
  checkDeviceErrors: ErrorDetail[] = [];
  validationDeviceErrors: ErrorDetail[] = [];
  csvDeviceErrors: ErrorDetail[] = [];
  device: OPCUADevice;
  opcuaConfigs: OPCUAConfig[];
  deleteDatapoints: boolean = false;

  public displayedColumns: string[] = ['select', 'key', 'parentName', 'name', 'description', 'value', 'unit'];
  public dataPointsDataSource = new MatTableDataSource<OPCUAEIoTDataPoint>([]);
  public deviceAddedDataPoints: OPCUAEIoTDataPoint[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {opcuaConfigs: OPCUAConfig[], device: OPCUADevice, activeSerial: string, location: string},
              public dialogRef: MatDialogRef<OpcuaEditCsvLabelsDialogComponent>,
              private opcuaDeviceService: OPCUADeviceService,
              public deviceService: DeviceService,
              private clipboard: Clipboard,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private csvService: NgxDataToCsvService) { }

  ngOnInit(): void {
    this.device = this.data.device;
    this.opcuaConfigs = this.data.opcuaConfigs;

    this.loadDatapoints();
  }

  loadDatapoints() {
    this.opcuaDeviceService.getEIoTDataPoints(this.data.activeSerial!!, this.device.id).subscribe(scanResult => {
      this.dataPointsDataSource = new MatTableDataSource(scanResult!!);
      this.dataPointsDataSource.sort = this.sort;
      this.dataPointsDataSource.sortingDataAccessor = this.sortingDataAccessor;
    });
  }

  exportSelectedDataPoints() {
    const exportDataPoints = this.deviceAddedDataPoints.map(dp => {
      return {
        "Selection state": 1,
        "Key": dp.key,
        "Device Name": dp.deviceName,
        "Parent Name": dp.parentName,
        "Data Point Name": dp.name,
        "Description": dp.description,
        "Value": dp.lastValue !== null ? this.formatValue(dp) : 'n/a',
        "Unit": dp.unit,
        "Existing labels": dp.labels ?? "",
      }
    });

    const gatewayLocation = this.data.location ?? "X";
    const deviceName = this.device?.name;
    const date = dayjs().format("YYYYMMDD_HHmmss");
    let exportFileName = `OPCUA-export_${gatewayLocation}_${deviceName}_${date}`;

    this.csvService.toCsv(exportDataPoints, exportFileName, {fieldSeparator: ';'});
  }

  showUploadDialog() {
    this.step = 2;
  }

  checkCSV() {
    this.opcuaDeviceService.validateOPCUADataPointLabels(this.data.activeSerial, this.data.device.id, this.file!).subscribe(errorResults => {
      this.checkDeviceErrors = errorResults;
      this.validationDeviceErrors = this.checkDeviceErrors.filter((e) => e.category == "Conflict");
      this.csvDeviceErrors = this.checkDeviceErrors.filter((e) => e.category != "Conflict");
      this.step = 3;
    });
  }

  fileChange($event: Event) {
    this.file = ($event.target as HTMLInputElement).files![0];
  }

  get checkEnabled(): boolean {
    return (this.device != null && this.file != null);
  }

  confirmDevice() {
    this.opcuaDeviceService.updateOPCUADataPointLabels(this.data.activeSerial, this.data.device.name, this.file!).subscribe({
      next: (_) => this.dialogRef.close({
        status: "200",
      }),
      error: (err) => {
        this.dialogRef.close({
          status: err.status.toString()
        });
      }
    });
  }

  async copyErrorToClipboard() {
    if (this.csvDeviceErrors.length == 0) {
      return;
    }

    const errors = [];
    errors.push(this.translateService.instant("add-device-dialog.csv.error"));
    for(let csvError of this.csvDeviceErrors) {
      let errorAsString = "Line: " + csvError.position + "\n";
      errorAsString += "Datapoint Key: " + csvError.elementHint + "\n";
      errorAsString += "Message: " + csvError.message + "\n";
      errors.push(errorAsString);
    }
    this.clipboard.copy(errors.join("\n"));
    await this.notificationService.success("Copied to clipboard");
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.deviceAddedDataPoints = [];
      return;
    }

    this.deviceAddedDataPoints = [...this.dataPointsDataSource.data];
  }

  isAllSelected() {
    const numSelected = this.deviceAddedDataPoints.length;
    const numRows = this.dataPointsDataSource.data.length;
    return numSelected === numRows;
  }

  isSelected(dataPoint: OPCUAEIoTDataPoint): boolean {
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1;
  }

  toggle(dataPoint: OPCUAEIoTDataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index > -1) {
      this.deviceAddedDataPoints.splice(index, 1);
    } else {
      this.deviceAddedDataPoints.push(dataPoint);
    }
  }

  findDataPointIndex(dataPoints: OPCUAEIoTDataPoint[], point: OPCUAEIoTDataPoint): number {
    return dataPoints.findIndex(dt => dt.id == point.id);
  }

  gotoStep(step: number) {
    this.step  = step;
  }

  private sortingDataAccessor(data: any, sortHeaderId: string): any {
    if(sortHeaderId == "key") {
      return parseInt(data[sortHeaderId]);
    }
    else if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }

  private sortDataPointsByKey(dataPoints: MBusDataPoint[]): MBusDataPoint[] {
    return dataPoints.sort((a, b) =>
      parseInt(a.key) > parseInt(b.key)
        ? 1 : -1
    );
  }

  formatValue(dataPoint: OPCUAEIoTDataPoint) {
    if(dataPoint.lastValue?.includes(".")) {
      const floatValue = parseFloat(dataPoint.lastValue);
      if(floatValue.toString() == dataPoint.lastValue) {
        const parts = floatValue.toString().split('.');
        if (parts[1].length > 3) {
          return floatValue.toFixed(3);
        }
      }
    }

    return dataPoint.lastValue;
  }
}
