<div class="table-container">
  <table mat-table matSort [dataSource]="configs">
    <ng-container matColumnDef="parentName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="parentName">{{'opcua-datapoint-table.header.parentname' | translate }}</th>
      <td mat-cell *matCellDef="let config">{{config.parentName}}</td>
    </ng-container>

    <ng-container matColumnDef="deviceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="deviceName">Device Name</th>
      <td mat-cell *matCellDef="let config">{{getDevice(config)?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="browseName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="browseName">Data Point Name</th>
      <td mat-cell *matCellDef="let config">{{config.name}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingFrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="pollingFrequency">{{'mbus-config-table.header.polling-frequency' | translate}}</th>
      <td mat-cell *matCellDef="let config">{{config.pollingFrequency}}</td>
    </ng-container>

    <ng-container matColumnDef="deleteDataPointsInIoT">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="deleteDataPointsInIoT">{{'opcua-config-table.header.delete-data-points-in-iot' | translate}}</th>
      <td mat-cell *matCellDef="let config">{{shouldDeleteInEIoT(config)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
